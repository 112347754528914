import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import FAQ from "../views/FAQ.vue";
import Status from "../views/Status.vue";
import About from "../views/About.vue";
import LandlordSurvey from "../views/LandlordSurvey.vue";
import TenantSurvey from "../views/TenantSurvey.vue";
import SurveySuccess from "../views/SurveySuccess.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/LandlordApplication",
    name: "LandlordApplication",
    component: () =>
      import(
        /* webpackChunkName: "LandlordApplication" */ "../views/LandlordApplication.vue"
      ),
    meta: {
      analytics: {
        path: "/LandlordApplication/",
      },
    },
  },
  {
    path: "/LandlordFileUpload/:applicantId",
    props: true,
    name: "LandlordFileUpload",
    component: () =>
      import(
        /* webpackChunkName: "LandlordFileUpload" */ "../views/LandlordFileUpload.vue"
      ),
    meta: {
      analytics: {
        path: "/LandlordFileUpload/",
      },
    },
  },
  {
    path: "/LandlordAdditionalFileUpload/:applicantId",
    props: true,
    name: "LandlordAdditionalFileUpload",
    component: () =>
      import(
        /* webpackChunkName: "LandlordFileUpload" */ "../views/LandlordAdditionalFileUpload.vue"
      ),
    meta: {
      analytics: {
        path: "/LandlordAdditionalFileUpload/",
      },
    },
  },
  {
    path: "/Success/:applicantId?",
    props: true,
    name: "Success",
    component: () =>
      import(
        /* webpackChunkName: "Success" */ "../views/Success.vue"
      ),
    meta: {
      analytics: {
        path: "/Success/",
      },
    },
  },
  {
    path: "/Status/:applicantId?",
    props: true,
    name: "Status",
    component: Status,
    meta: {
      analytics: {
        path: "/Status/",
      },
    },
  },
  {
    path: "/FAQ",
    name: "FAQ",
    component: FAQ,
    meta: {
      analytics: {
        path: "/FAQ/",
      },
    },
  },
  {
    path: "/About",
    name: "About",
    component: About,
    meta: {
      analytics: {
        path: "/About/",
      },
    },
  },
  {
    path: "/LandlordSurvey/:applicantId?",
    props: true,
    name: "LandlordSurvey",
    component: LandlordSurvey,
    meta: {
      analytics: {
        path: "/LandlordSurvey/",
      },
    },
  },
  {
    path: "/TenantSurvey/:applicantId?",
    props: true,
    name: "TenantSurvey",
    component: TenantSurvey,
    meta: {
      analytics: {
        path: "/TenantSurvey/",
      },
    },
  },
  {
    path: "/SurveySuccess",
    name: "SurveySuccess",
    component: SurveySuccess,
    meta: {
      analytics: {
        path: "/SurveySuccess/",
      },
    },
  },
];

const router = new VueRouter({
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 };
  },
});

export default router;
