const options = {
  data() {
    return {
      SurveyCheckboxOptions: {
        agreementIncludesOptions: [
          {
            "value": "Payment plan",
            "text": "Payment plan",
          },
          {
            "value": "A move out date",
            "text": "A move out date",
          },
          {
            "value": "Renewal of the lease",
            "text": "Renewal of the lease",
          },
          {
            "value": "Repairs",
            "text": "Repairs",
          },
          {
            "value": "Reduce or forgive back rent",
            "text": "Reduce or forgive back rent",
          },
          {
            "value": "Other",
            "text": "Other",
          },
        ],
        landlordNoAgreementOptions: [
          {
            "value": "The tenant was unavailable.",
            "text": "The tenant was unavailable.",
          },
          {
            "value": "We disagree over rent or other charges.",
            "text": "We disagree over rent or other charges.",
          },
          {
            "value": "The tenant does not have resources to pay ongoing rent.",
            "text": "The tenant does not have resources to pay ongoing rent.",
          },
          {
            "value": "Lack of rental assistance.",
            "text": "Lack of rental assistance.",
          },
          {
            "value": "There are issues other than rent.",
            "text": "There are issues other than rent.",
          },
          {
            "value": "Other",
            "text": "Other",
          },
        ],
        tenantNoAgreementOptions: [
          {
            "value": "We disagree over rent or other charges.",
            "text": "We disagree over rent or other charges.",
          },
          {
            "value": "I can’t afford the rent or payment plan.",
            "text": "I can’t afford the rent or payment plan.",
          },
          {
            "value": "I need rental assistance to help with back rent.",
            "text": "I need rental assistance to help with back rent.",
          },
          {
            "value": "The landlord or attorney does not have the info we need.",
            "text": "The landlord or attorney does not have the info we need.",
          },
          {
            "value": "Issues with contact information (could not contact)",
            "text": "Issues with contact information (could not contact)",
          },
          {
            "value": "There are issues other than rent.",
            "text": "There are issues other than rent.",
          },
          {
            "value": "Other",
            "text": "Other",
          },
        ],
        issuesOtherThanRent: [
          {
            "value": "Repairs and/or unsafe conditions",
            "text": "Repairs and/or unsafe conditions",
          },
          {
            "value": "Pets",
            "text": "Pets",
          },
          {
            "value": "Smoking",
            "text": "Smoking",
          },
          {
            "value": "Guests",
            "text": "Guests",
          },
          {
            "value": "Lease renewal or the term of the lease",
            "text": "Lease renewal or the term of the lease",
          },
        ],
      },
      MultipleChoiceFieldOptions: {
        YesNo: ["Yes", "No"],
        //YesNoExpanded: ["Yes", "Maybe", "Unlikely", "No"],
        YesNoNotSure: ["Yes", "No", "Not Sure"],
        AgreementScale: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
        //PaymentComfortScale: ["1: Very Comfortable", "2: Somewhat Comfortable", "3: Not Comfortable", "4: Very Uncomfortable"],
        Countries: [
          "United States",
          "Afghanistan",
          "Aland Islands",
          "Albania",
          "Algeria",
          "American Samoa",
          "Andorra",
          "Angola",
          "Anguilla",
          "Antarctica",
          "Antigua and Barbuda",
          "Argentina",
          "Armenia",
          "Aruba",
          "Australia",
          "Austria",
          "Azerbaijan",
          "Bahamas",
          "Bahrain",
          "Bangladesh",
          "Barbados",
          "Belarus",
          "Belgium",
          "Belize",
          "Benin",
          "Bermuda",
          "Bhutan",
          "Bolivia, Plurinational State of",
          "Bonaire, Sint Eustatius and Saba",
          "Bosnia and Herzegovina",
          "Botswana",
          "Bouvet Island",
          "Brazil",
          "British Indian Ocean Territory",
          "Brunei Darussalam",
          "Bulgaria",
          "Burkina Faso",
          "Burundi",
          "Cambodia",
          "Cameroon",
          "Canada",
          "Cape Verde",
          "Cayman Islands",
          "Central African Republic",
          "Chad",
          "Chile",
          "China",
          "Christmas Island",
          "Cocos (Keeling) Islands",
          "Colombia",
          "Comoros",
          "Congo",
          "Congo, The Democratic Republic of the",
          "Cook Islands",
          "Costa Rica",
          "Cote d'Ivoire",
          "Croatia",
          "Cuba",
          "Curacao",
          "Cyprus",
          "Czech Republic",
          "Denmark",
          "Djibouti",
          "Dominica",
          "Dominican Republic",
          "Ecuador",
          "Egypt",
          "El Salvador",
          "Equatorial Guinea",
          "Eritrea",
          "Estonia",
          "Ethiopia",
          "Falkland Islands (Malvinas)",
          "Faroe Islands",
          "Fiji",
          "Finland",
          "France",
          "French Guiana",
          "French Polynesia",
          "French Southern Territories",
          "Gabon",
          "Gambia",
          "Georgia",
          "Germany",
          "Ghana",
          "Gibraltar",
          "Greece",
          "Greenland",
          "Grenada",
          "Guadeloupe",
          "Guam",
          "Guatemala",
          "Guernsey",
          "Guinea",
          "Guinea-Bissau",
          "Guyana",
          "Haiti",
          "Heard Island and McDonald Islands",
          "Holy See (Vatican City State)",
          "Honduras",
          "Hong Kong",
          "Hungary",
          "Iceland",
          "India",
          "Indonesia",
          "Iran, Islamic Republic of",
          "Iraq",
          "Ireland",
          "Isle of Man",
          "Israel",
          "Italy",
          "Jamaica",
          "Japan",
          "Jersey",
          "Jordan",
          "Kazakhstan",
          "Kenya",
          "Kiribati",
          "Korea, Democratic People's Republic of",
          "Korea, Republic of",
          "Kosovo",
          "Kuwait",
          "Kyrgyzstan",
          "Lao People's Democratic Republic",
          "Latvia",
          "Lebanon",
          "Lesotho",
          "Liberia",
          "Libya",
          "Liechtenstein",
          "Lithuania",
          "Luxembourg",
          "Macao",
          "Macedonia, The Former Yugoslav Republic of",
          "Madagascar",
          "Malawi",
          "Malaysia",
          "Maldives",
          "Mali",
          "Malta",
          "Marshall Islands",
          "Martinique",
          "Mauritania",
          "Mauritius",
          "Mayotte",
          "Mexico",
          "Micronesia, Federated States of",
          "Moldova, Republic of",
          "Monaco",
          "Mongolia",
          "Montenegro",
          "Montserrat",
          "Morocco",
          "Mozambique",
          "Myanmar",
          "Namibia",
          "Nauru",
          "Nepal",
          "Netherlands",
          "New Caledonia",
          "New Zealand",
          "Nicaragua",
          "Niger",
          "Nigeria",
          "Niue",
          "Norfolk Island",
          "Northern Cyprus",
          "Northern Mariana Islands",
          "Norway",
          "Oman",
          "Pakistan",
          "Palau",
          "Palestine, State of",
          "Panama",
          "Papua New Guinea",
          "Paraguay",
          "Peru",
          "Philippines",
          "Pitcairn Islands",
          "Poland",
          "Portugal",
          "Puerto Rico",
          "Qatar",
          "Reunion",
          "Romania",
          "Russian Federation",
          "Rwanda",
          "Western Sahara",
          "Saint Barthelemy",
          "Saint Helena, Ascension and Tristan da Cunha",
          "Saint Kitts and Nevis",
          "Saint Lucia",
          "Saint Martin (French Part)",
          "Saint Pierre and Miquelon",
          "Saint Vincent and the Grenadines",
          "Samoa",
          "San Marino",
          "Sao Tome and Principe",
          "Saudi Arabia",
          "Senegal",
          "Serbia",
          "Seychelles",
          "Sierra Leone",
          "Singapore",
          "Sint Maarten (Dutch Part)",
          "Slovakia",
          "Slovenia",
          "Solomon Islands",
          "Somalia",
          "Somaliland",
          "South Africa",
          "South Georgia and the South Sandwich Islands",
          "South Ossetia",
          "South Sudan",
          "Spain",
          "Sri Lanka",
          "Sudan",
          "Suriname",
          "Svalbard and Jan Mayen",
          "Swaziland",
          "Sweden",
          "Switzerland",
          "Syrian Arab Republic",
          "Taiwan, Province of China",
          "Tajikistan",
          "Tanzania, United Republic of",
          "Thailand",
          "Timor-Leste",
          "Togo",
          "Tokelau",
          "Tonga",
          "Transnistria",
          "Trinidad and Tobago",
          "Tunisia",
          "Turkey",
          "Turkmenistan",
          "Turks and Caicos Islands",
          "Tuvalu",
          "Uganda",
          "Ukraine",
          "United Arab Emirates",
          "United Kingdom",
          "United States Minor Outlying Islands",
          "Uruguay",
          "Uzbekistan",
          "Vanuatu",
          "Venezuela, Bolivarian Republic of",
          "Vietnam",
          "Virgin Islands, British",
          "Virgin Islands, US",
          "Wallis and Futuna",
          "Yemen",
          "Zambia",
          "Zimbabwe",
        ],
        TitleOfPropertyManagementRepresentative: ["Property Management Company", "Agent"],
      },
      SubmitStatus: {
        Value: null,
        Options: {
          PENDING: "PENDING",
          OK: "OK",
          NOT_FOUND: "NOT_FOUND",
          ERROR: "ERROR",
        },
      },
      NumberOfTenants: 5,
    };
  },
};

export default options;
