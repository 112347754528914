<template>
  <div class="container">
    <div class="columns is-centered">
      <div class="column">
        <h1>Home</h1>
        <div>
          Welcome to the City of Philadelphia’s FREE Eviction Diversion Program (EDP).
          The Program provides resources for landlords and tenants in residential rental
          properties to resolve issues while avoiding a costly or drawn-out court process.
          Per City Ordinance # 220655, landlord good faith participation in EDP is required
          before seeking a legal eviction through court. Resources may include mediation and
          funding that covers tenant’s rent arrears.
        </div>
        <br>
        <div>
          <b>If you are a Landlord or Landlord Representative:</b><br><br> Please complete
          this application carefully to participate. Information shared on this application,
          including your contact information, may be shared directly with the tenant.  This
          application is available in multiple languages through the “Select Language” box
          at the top of this screen. If you need assistance or have any questions, please
          review the FAQ tab or contact:  <a href="mailto:EvictionDiversionProgram@coraservices.org">
            EvictionDiversionProgram@coraservices.org</a>.
        </div><br>
        <div>
          As of January 2023, qualifying Eviction Diversion Program applicants may also be
          eligible to receive Targeted Financial Assistance (TFA). To be considered for
          financial assistance, the amount owed in arrears on your application must match the
          ledger or rent roll uploaded with your EDP application. Applications approved prior
          to January 26, 2023 will not be eligible for TFA.
        </div><br>
        <div>
          <b>If you are a Tenant:</b><br><br>
          Do not fill out this application. Please visit the FAQ page for information and resources
          or contact PLA’s Eviction Diversion Program Tenant Hotline at 215-523-9501.
        </div><br>
        <callout type="danger">
          Please ensure you are using <a href="https://dpd-eviction-diversion-documents.s3.amazonaws.com/NOTICE+OF+DIVERSION+RIGHTS+FINAL+3.10.2022.pdf">
            the current version</a> of the Notice of Tenant Rights (2022 NOR).<br><br>
          Please <a href="https://dpd-eviction-diversion-documents.s3.amazonaws.com/Eviction+Diversion+Evaluation+-+Final.pdf">click</a> for the latest Eviction Diversion Program evaluation.<br><br>
          To see the recent changes made to this webpage, you may need to clear your web browser cache.<br>
        </callout>
        <callout type="warning">
          <b>Notice:</b> The maximum arrears eligible for Targeted Financial Assistance (TFA) has increased to $3,500.
        </callout>
        <!--<callout type="warning">
          If you are planning on applying for rental assistance or have applied, you will be automatically be added to
          the Eviction Diversion Program. You do not have to apply using this portal. For more information about Rent
          Assist, visit this site:
          <a
            href="https://phlrentassist.org/"
            target="#"
          >https://phlrentassist.org/</a>.
          <br>
          <br>
          If you have any questions, please reach out to: <a href="mailto:EvictionDiversionProgram@coraservices.org">
          EvictionDiversionProgram@coraservices.org</a>
        </callout>-->

        <!--<callout type="danger">
          The City is updating the Eviction Diversion Webportal and will begin accepting applications
          on Monday, January 10, 2022. We appreciate your patience as we transition.
        </callout>-->
        <router-link
          to="/LandlordApplication"
          tag="button"
          class="button is-primary"
        >
          Apply Now
        </router-link>
        <br>
        <br><!--test cloudfront cache-->
        <callout type="info">
          <strong>For the latest COVID-19 information, visit <a href="https://phila.gov/COVID-19">phila.gov/COVID-19</a>.
            Text COVIDPHL to 888-777 to receive updates to your phone.</strong>
          <br>
          <br>
          <div>
            <h3>Contact @PHLPlanDevelop</h3>

            <li2>
              <a
                href="https://twitter.com/PHLPlanDevelop"
                target="#"
              > <i class="fab fa-twitter" /></a>
            </li2>
            <li2>
              <a
                href="https://www.facebook.com/PHLPlanDevelop/"
                target="#"
              ><i class="fab fa-facebook" /></a>
            </li2>
            <li2>
              <a
                href="https://www.instagram.com/PHLPlanDevelop/"
                target="#"
              ><i class="fab fa-instagram" /></a>
            </li2>
          </div>
        </callout>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {},
};
</script>
<style scoped>
li2 {
  list-style-type: none;
  display: inline;
  padding-right: 25px;
}
i {
  font-size: 35px;
}
</style>
