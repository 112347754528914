import Vue from "vue";
import App from "./App.vue";
//import "./registerServiceWorker";
import VueGtag from "vue-gtag";
import router from "./router";
import store from "./store";

import * as PhilaUI from '@phila/phila-ui';
import VueSimpleAlert from "vue-simple-alert";
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required, email, length, min, numeric, double } from 'vee-validate/dist/rules';
import { getPropertyValueOrDefault } from '@/utilities/functions';
import { withValidation } from 'vee-validate';

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

const VeeTextbox = withValidation(PhilaUI.Textbox);
const VeeDropdown = withValidation(PhilaUI.Dropdown);
const VeeRadio = withValidation(PhilaUI.Radio);
Vue.use(VueSimpleAlert);
Vue.component('VeeTextbox', VeeTextbox);
Vue.component('VeeDropdown', VeeDropdown);
Vue.component('VeeRadio', VeeRadio);

extend('numeric', numeric);
extend('length', length);
extend('min', min);
extend('min_array_length', {
  validate(value, args) {
    return value.length >= args.length;
  },
  message (value, args) {
    return `Select at least ${args.length}`;
  },
  params: [ 'length' ],
});
extend('required', {
  ...required,
  message: 'This field is required',
});
extend('required_cellmessage', {
  ...required,
  message: 'This field is required unless a valid email address is also provided.',
});
extend('required_emailmessage', {
  ...required,
  message: 'This field is required unless a valid cell phone number is also provided.',
});
extend('email', {
  ...email,
  message: 'Please enter a valid email address (for example: MyName@MyEmail.com)',
});
extend('doubleCurrency', {
  ...double,
  message: 'Please enter a valid amount with 2 decimals (for example: 99.99)',
});
extend('phone', {
  message: 'Please enter a valid phone number (for example: 123-456-7890 or 9876543210)',
  validate (value) {
    return new Promise(resolve => {
      const isValid = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(value);
      resolve({ valid: isValid });
    });
  },
});

Vue.use(PhilaUI);
const isProduction = process.env.NODE_ENV === "production";
Vue.config.productionTip = true;
Vue.config.devtools = !isProduction;

Vue.use(VueGtag, {
  config: { id: "G-ZDCRMJ7NZP" },
  pageTrackerTemplate(to) {
    const pagePath = getPropertyValueOrDefault(["meta", "analytics", "path"], to) || to.path;
    return {
      page_title: to.name,
      page_path: pagePath,
      page_location: window.location.href,
    };
  },
}, router);


new Vue({
  router,
  store,
  mounted: function () {
    // fix for IE 11
    if (!!window.MSInputMethodContext && !!document.documentMode) {
      window.addEventListener('hashchange', this.hashChangeHandler);
    }
  },
  destroyed: function () {
    // fix for IE 11
    if (!!window.MSInputMethodContext && !!document.documentMode) {
      window.removeEventListener('hashchange', this.hashChangeHandler);
    }
  },
  methods: {
    hashChangeHandler: function () {
      var target = window.location.hash;
      this.$router.push(target.substring(1, target.length));
    },
  },
  render: h => h(App),
}).$mount("#app");
