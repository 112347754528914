<template>
  <div class="container">
    <div class="columns is-centered">
      <div class="column">
        <ValidationObserver
          v-slot="{ errors }"
          tag="div"
        >
          <input-form
            ref="form"
            :errors="errors"
            name="LandlordSurveyFrm"
            @submit.prevent="handleSubmit"
          >
            <h1>City of Philadelphia’s Eviction Diversion Program</h1>
            <h1>Exit Interview (Landlord)</h1>
            <callout type="info">
              <div>
                You participated in the Eviction Diversion Program. Your feedback will help improve the program.
                This survey is optional and takes 3 minutes or less to complete.
              </div>
            </callout><br>
            <callout type="danger">
              <div>
                Information is confidential and will not be shared with the tenant.<br><br>
                By completing this survey, you consent to having compiled anonymous feedback reviewed by the City and
                City partners to help make improvements and updates. Your responses will not be shared with anyone outside of the Eviction Diversion Program.<br><br>
                For more information, visit <a href="https://eviction-diversion.phila.gov/#/About">https://eviction-diversion.phila.gov/#/About</a>
              </div>
            </callout><br>
            <div class="columns">
              <div class="column">
                <label><b>Enter applicant ID:</b></label>
                <textbox
                  v-model.trim="ApplicantId"
                  mode="eager"
                  :disabled="true"
                  name="txt-applicant-id"
                  placeholder="Applicant ID:"
                />
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <label><b>Enter tenant property address:</b></label>
                <vee-textbox
                  v-model="Address.RawAddress"
                  mode="eager"
                  name="txt-test-address-search"
                  placeholder="Search for a City of Philadelphia Street Address..."
                  list.native="addressCandidateList"
                  @input="getAddressCandidatesFromAis()"
                />
                <div class="columns">
                  <div class="column">
                    <div
                      v-show="ShowCandidateTable"
                      class="table-container"
                    >
                      <div v-if="AddressCandidatesLoaded">
                        <h4
                          v-if="Address.Candidates.length > 1"
                        >
                          We've found multiple addresses like that, please
                          select yours from the list below, or add more
                          information to the search above to narrow your
                          results
                        </h4>
                        <h4 v-else>
                          We've found a property like the one you entered
                          above, please select it from below to auto populate
                          the tenant address fields. Please fill in the
                          apartment / unit number below for the tenant if
                          applicable.
                        </h4>
                        <table>
                          <thead>
                            <tr>
                              <th>Address</th>
                              <th>ZIP Code</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="item in Address.Candidates"
                              :key="item.id"
                            >
                              <td>
                                <input
                                  :id="`candidate-${item.id}`"
                                  :value="item.selected"
                                  type="radio"
                                  name="selectedCandidate"
                                  @click="setSelectedAddressCandidate(item)"
                                >
                                <label
                                  class="address-label"
                                  :for="`candidate-${item.id}`"
                                >{{ item.Street1 }}</label>
                              </td>
                              <td>{{ item.ZipCode }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div v-else>
                        <span>Loading...</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="columns">
                  <div class="column">
                    <vee-textbox
                      v-model="Address.Street1"
                      mode="eager"
                      name="txt-address-street1"
                      placeholder="Street Address"
                      rules="required"
                      required
                      error="This field is required"
                    />
                  </div>
                  <div class="column">
                    <vee-textbox
                      v-model="Address.Street2"
                      name="txt-address-street2"
                      placeholder="Apt / Unit"
                    />
                  </div>
                </div>
                <div class="columns">
                  <div class="column">
                    <vee-textbox
                      v-model="Address.City"
                      mode="eager"
                      name="txt-address-city"
                      placeholder="City"
                      rules="required"
                      required
                      error="This field is required"
                    />
                  </div>
                  <div class="column">
                    <vee-textbox
                      v-model="Address.State"
                      name="txt-address-state"
                      placeholder="State"
                      rules="required"
                      required
                      error="This field is required"
                    />
                  </div>
                </div>
                <div class="columns">
                  <div class="column">
                    <vee-textbox
                      v-model="Address.Zip"
                      mode="eager"
                      name="txt-address-zip"
                      placeholder="Zip Code"
                      rules="required"
                      required
                      error="This field is required"
                    />
                  </div>
                  <div class="column">
                    <vee-textbox
                      v-model="Address.Country"
                      name="txt-address-country"
                      placeholder="Country"
                      rules="required"
                      required
                      error="This field is required"
                    />
                  </div>
                </div>
                <label><b>Enter Name (optional):</b></label>
                <div class="columns">
                  <div class="column">
                    <vee-textbox
                      v-model="landlordFirstName"
                      mode="eager"
                      name="txt-tenant-first-name"
                      placeholder="First Name"
                    />
                  </div>
                  <div class="column">
                    <vee-textbox
                      v-model="landlordLastName"
                      mode="eager"
                      name="txt-tenant-last-name"
                      placeholder="Last Name"
                    />
                  </div>
                </div>
                <div class="column">
                  <radio
                    v-model="negotiationMayStopEviction"
                    :options="MultipleChoiceFieldOptions.AgreementScale"
                    label="Going into this negotiation, I was hopeful that the conversation would prevent an eviction."
                  />
                  <radio
                    v-model="agreementReached"
                    :options="MultipleChoiceFieldOptions.YesNo"
                    label="In the last 30 days, I reached an agreement with my tenant."
                  />
                  <div
                    v-if="agreementReached === 'Yes'"
                    class="column"
                  >
                    <radio
                      v-model="agreementFairnessScale"
                      label="The agreement is fair."
                      :options="MultipleChoiceFieldOptions.AgreementScale"
                    />
                    <radio
                      v-model="agreementIsInWriting"
                      label="I have the agreement in writing such as text, email, or written document."
                      :options="MultipleChoiceFieldOptions.YesNoNotSure"
                    />
                    <div class="columns">
                      <div class="column">
                        <checkbox
                          v-model="agreementIncludes"
                          label="The agreement includes:"
                          :options="SurveyCheckboxOptions.agreementIncludesOptions"
                          :text-key="textKey"
                          :value-key="valueKey"
                        />
                        <div
                          v-if="agreementIncludes.includes('Other')"
                          class="columns"
                        >
                          <div class="column">
                            <vee-textbox
                              v-model="agreementIncludesOther"
                              mode="eager"
                              name="text-agreement-includes-other"
                              placeholder="Please describe the other agreement."
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <vee-textbox
                      v-model="agreementReachedFeedback"
                      mode="eager"
                      name="txt-yes-feedback"
                      placeholder="I want to share more feedback."
                      maxlength="500"
                    />
                    <radio
                      v-model="shareSuccess"
                      label="Please contact me to share my success and help other landlords and tenants reach agreements."
                      :options="MultipleChoiceFieldOptions.YesNo"
                    />
                  </div>
                  <div
                    v-else-if="agreementReached === 'No'"
                    class="column"
                  >
                    <div class="columns">
                      <div class="column">
                        <checkbox
                          v-model="landlordNoAgreementReasons"
                          label="Why not?"
                          :options="SurveyCheckboxOptions.landlordNoAgreementOptions"
                          :text-key="textKey"
                          :value-key="valueKey"
                        />
                        <!--<pre>Selected option(s): {{ noAgreementReasonsList }}</pre>-->
                      </div>
                    </div>
                    <div
                      v-if="landlordNoAgreementReasons.includes('There are issues other than rent.')"
                    >
                      <checkbox
                        v-model="landlordIssuesOtherThanRent"
                        class="column"
                        label="Please check additional reasons:"
                        :options="SurveyCheckboxOptions.issuesOtherThanRent"
                        :text-key="textKey"
                        :value-key="valueKey"
                      />
                    </div>
                    <div
                      v-if="landlordNoAgreementReasons.includes('Other')"
                      class="columns"
                    >
                      <div class="column">
                        <vee-textbox
                          v-model="noAgreementReasonsOther"
                          mode="eager"
                          name="text-agreement-includes-other"
                          placeholder="Please describe the other reasons no agreement was reached."
                        />
                      </div>
                    </div>
                  </div>

                  <button
                    type="button"
                    class="button is-primary"
                    :disabled="SubmitStatus.Value === 'PENDING' || SubmitStatus.Value === 'OK'"
                    @click="handleSurveySubmit"
                  >
                    Submit
                  </button>

                  <div class="column">
                    <div v-if="SubmitStatus.Value === SubmitStatus.Options.PENDING">
                      Submitting...
                    </div>
                    <div v-if="SubmitStatus.Value === SubmitStatus.Options.OK">
                      OK
                    </div>
                    <div v-if="SubmitStatus.Value === SubmitStatus.Options.ERROR">
                      Error
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </input-form>
        </validationobserver>
      </div>
    </div>
  </div>
</template>

<script>
import options from "@/utilities/options";
import {
  dataObjectToXml,  //For exported objects that are not defined as "defaults" (see dataObjectToXml in "survey-submission-data-object"), all objects needed must be imported in this bracket format for Javascript to return data correctly.
  delay,
} from "@/utilities/functions";
import buildSurveySubmissionDataObject from "@/utilities/data-objects/survey-submission-data-object";
import axios from "axios";
export default {
  name: "LandlordSurvey",
  mixins: [options],
  props: {
    applicantId: { type: String, default: null },
  },
  data() {
    return {
      submissionType: "Landlord",
      ShowCandidateTable: false,
      AddressCandidatesLoaded: false,
      Address: {
        RawAddress: null,
        Candidates: [],
        Street1: null,
        Street2: null,
        City: "Philadelphia",
        State: "Pennsylvania",
        Zip: null,
        Country: "United States",
      },
      landlordFirstName: null,
      landlordLastName: null,
      negotiationMayStopEviction: null,
      agreementReached: null,
      agreementFairnessScale: null,
      agreementIsInWriting: null,
      agreementIncludes: [],
      agreementIncludesOther: null,
      agreementReachedFeedback: null,
      shareSuccess: null,
      landlordNoAgreementReasons: [],
      noAgreementReasonsOther: null,
      landlordIssuesOtherThanRent: [],
      textKey: 'text',
      valueKey: 'value',
    };
  },
  computed: {
    landlordNoAgreementReasonsList() {
      return this.landlordNoAgreementReasons
        .map(landlordNoAgreementReasons => landlordNoAgreementReasons)
        .join(";");
    },
    issuesOtherThanRentList() {
      return this.landlordIssuesOtherThanRent
        .map(landlordIssuesOtherThanRent => landlordIssuesOtherThanRent)
        .join(";");
    },
    agreementIncludesList() {
      return this.agreementIncludes
        .map(agreementIncludes => agreementIncludes)
        .join(";");
    },
  },
  created() {
    var vData = this;
    if (vData.applicantId) {
      vData.ApplicantId = vData.applicantId;
    }
  },
  methods: {
    getAddressCandidatesFromAis() {
      var vData = this;
      //vData.AddressCandidatesLoaded = false;
      vData.ShowCandidateTable = true;

      const aisUrl = `https://api.phila.gov/ais/v1/search/${vData.Address.RawAddress}?gatekeeperKey=2d130e3603f8931d82d39b3913bffe18`;
      delay(() => {
        axios.get(aisUrl).then((result) => {
          if (result && result.data) {
            vData.Address.Candidates = result.data.features.map(
              (i, idx) => ({
                id: idx,
                Street1: i.properties.street_address,
                ZipCode: i.properties.zip_code,
                selected: false,
              }),
            );
            vData.AddressCandidatesLoaded = true;
          } else {
            vData.ShowCandidateTable = false;
            vData.Address.Candidates = [];
            vData.Address = {};
          }
        });
      }, 1000);
    },

    setSelectedAddressCandidate(item) {
      var vData = this;
      vData.Address.Street1 = item.Street1;
      vData.Address.Zip = item.ZipCode;
    },

    async handleSurveySubmit() {
      var vData = this;
      if (vData.agreementReached == 'Yes') {
        vData.landlordNoAgreementReasons = [];
        vData.landlordIssuesOtherThanRent = [];
        vData.noAgreementReasonsOther = "";

      }
      if (vData.agreementReached == 'No') {
        vData.tenantCanMaintainPayments = "";
        vData.agreementFairnessScale = "";
        vData.agreementIsInWriting = "";
        vData.agreementIncludes = [];
        vData.agreementIncludesOther = "";
        vData.agreementReachedFeedback = "";
        vData.shareSuccess = "";
      }
      vData.SubmitStatus.Value = vData.SubmitStatus.Options.PENDING;
      const isInputValid = await vData.$refs.form.$parent.validate();
      if (isInputValid) {
        const dataObject = buildSurveySubmissionDataObject(vData);
        const xmlDataObject = dataObjectToXml(dataObject);
        const requestObject = {
          table: "bskga6khq",
          action: "API_AddRecord",
          data: xmlDataObject,
        };

        axios
          .post(
            "https://djql8jp3sb.execute-api.us-east-1.amazonaws.com/V1/Eviction-Diversion-QuickBase-Request-Proxy",
            requestObject,
          )
          .then((response) => {
            console.log(response);
            vData.SubmitStatus.Value = vData.SubmitStatus.Options.OK;
            //return this.$alert("Thank you for your response.");
            vData.$router.push({
              name: "SurveySuccess",
            });

          })
          .catch((response) => {
            response;
            vData.SubmitStatus.Value = vData.SubmitStatus.Options.ERROR;
          });

      } else {
        vData.SubmitStatus.Value = vData.SubmitStatus.Options.ERROR;
      }
    },
  },
  //},
};
</script>


<style lang="scss" scoped>
.columns,
.column,
.content div {
  margin-bottom: 0;
}
.address-label {
  padding-left: 5px;
}
</style>
