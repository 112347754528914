<template>
  <div class="container">
    <div class="columns is-centered">
      <div class="column">
        <h1>Frequently Asked Questions (FAQ)</h1><!--jdw deployment test 10/16-->
        <h2>
          City of Philadelphia's Eviction Diversion Program
        </h2>

        <p class="faq-line">
          What is the Eviction Diversion Program?
        </p>
        <p class="faq-line-indent">
          The Eviction Diversion Program (EDP) is a free City service for residential tenants and landlords.
          The Program is an opportunity to work out an agreement to prevent the costs and burden of a legal eviction.
          Applications are now open to landlords through <a href="https://eviction-diversion.phila.gov/#/">
            <u>https://eviction-diversion.phila.gov/#</u>
          </a>.
        </p>
        <p class="faq-line-indent">
          Applications are assigned appropriate pathways depending on the amount of back rent and fees that the
          landlord says is owed and additional factors.
          A tenant may be assigned a housing counselor, mediation session or additional resources like a webinar.
          Tenants and landlords are encouraged to immediately try to work out agreements.
        </p>
        <p class="faq-line-indent">
          If assigned, a city-funded housing counselor will meet with the tenants before mediation to assess finances and
          find out if other issues need to be addressed. Housing counselors attend mediation sessions along with the tenant.
          Landlords are encouraged to communicate with housing counselors.

          <br><br>Mediation sessions are telephone conference calls scheduled in one-hour sessions. Landlords and tenants
          receive automated notices with the time and date of the mediation. For details visit:
          <a href="https://eviction-diversion.phila.gov/#/FAQ"><u>https://eviction-diversion.phila.gov/#/FAQ.</u></a>
          On the call, a trained mediator facilitates the conversation between landlord and tenant. The landlord says
          what the landlord is seeking. The tenant and the tenant’s housing counselor share the tenant’s goal and available resources.

          <br><br>If the landlord and tenant reach an agreement, the mediation coordinator writes down the agreement and
          provides a copy to both parties. Both parties review the agreement and follow the steps determined in mediation.
          These steps may include regular payments from the tenant and other issues the parties want to resolve such as
          repairs, utility bills and access to the property.

          <br><br>Landlords and tenants may reach agreements without the use of a housing counselor or mediation.
        </p>

        <p class="faq-line">
          Who is eligible for the Eviction Diversion Program?
        </p>
        <p class="faq-line-indent">
          The Program is available for residential, tenant-occupied properties in Philadelphia.
          As of January 1, 2022, Philadelphia Code 9-811 requires landlords to participate in good faith in the Eviction Diversion Program prior to filing an eviction case against their tenant in court.
          For more information, visit the
          <router-link to="/About">
            <u> About Page</u>.
          </router-link>
        </p>
        <p class="faq-line-indent" />
        <p class="faq-line">
          I am a Landlord. What do I need to apply for the Eviction Diversion Program?
        </p>
        <p class="faq-line-indent">
          Here are the steps a landlord must take under Philadelphia Code 9-811:
        </p>
        <ol class="faq-list">
          <li>
            Send the tenant the <a
              href="https://dpd-eviction-diversion-documents.s3.amazonaws.com/NOTICE+OF+DIVERSION+RIGHTS+FINAL+3.10.2022.pdf"
              target="_blank"
            ><u>2022 Notice of Diversion Rights</u></a> by hand delivery or mail with proof of delivery.
          </li>
          <li>
            Apply for Eviction Diversion through the Eviction Diversion Web-Portal here: <a href="https://eviction-diversion.phila.gov/#"><u>https://eviction-diversion.phila.gov/#</u></a>.
            The landlord must provide current, accurate contact information for the tenant and complete the other requested information
            to help the Eviction Diversion Program support the landlord and tenant in resolving all issues.  The application will be
            reviewed for completeness.
          </li>
          <li>
            Respond timely to communication from a tenant or the Housing Counselor assigned to try to resolve issues through
            rental assistance or negotiated agreements.
          </li>
          <li>Respond timely to program representatives.</li>
          <li>
            Negotiate in good faith with a tenant. Where assigned, attend a mediation session by phone if scheduled
            and participate in good faith.
          </li>
        </ol>

        <b>What documents do I need to apply for Eviction Diversion?</b>
        <p class="faq-line-indent">
          <ul>
            <li>
              <b>Proof you sent your tenant the Notice of Diversion Rights:</b> You can find the 2022 Notice of Diversion Rights
              <a href="https://dpd-eviction-diversion-documents.s3.amazonaws.com/NOTICE+OF+DIVERSION+RIGHTS+FINAL+3.10.2022.pdf"><u>here</u></a>.
            </li>
            <li>
              <b>Proof of rent owed: </b>Ledger or rent roll document verifying arrears. The document must include the property address,
              the landlord name, the tenant name, and dates corresponding with payments made and balances owed.
            </li>
            <li>
              <b>Proposed payment agreement: </b> It is highly recommended, but not required, to provide a proposed payment agreement
              or other agreement that you are willing to discuss with your tenant. You can find the template
              <a href="https://dpd-eviction-diversion-documents.s3.amazonaws.com/Employee+ID+attribute+update+field.docx"><u>here</u>.</a>
            </li>
          </ul>
        </p>
        <p class="faq-line-indent">
          Files should be uploaded separately. For each document, include all pages in a single file.<br><br>
        </p>
        <b>What are acceptable forms of proof that the Notice of Diversion Rights was sent?</b>
        <p class="faq-line-indent">
          <ul>
            <li>An image of an envelope addressed to the tenant and stamped.</li>
            <li>
              A certified mail receipt or green card showing the tenant’s name and address./li>
            </li><li>
              A landlord-made “affidavit of service” or some official statement in writing where the landlord
              says they delivered the Notice to the tenant.
            </li>
            <li>A tenant signature on the notice.</li>
            <li>A form the landlord made up for the tenant to sign saying they received the notice, signed by the tenant.</li>
            <li>A USPS mail receipt showing postage paid and the tenant's name and address.</li>
            <li>An email or text to the tenant indicating delivery of the notice.</li>
            <li>A photo of the notice taped to the door or mailbox that shows the address.</li>
          </ul>
        </p>

        <br><b>What is a ledger or rent roll document?</b><br><br>
        The following types of documents are acceptable as proof of rent owed. The document must include the property address,
        landlord name, tenant name, and dates for all payments made and balances owed.<br>
        <p class="faq-line-indent">
          <ul>
            <li>A formal ledger from a property management system.</li>
            <li>A Word document, Excel spreadsheet, or handwritten document listing months of rent owed and any other fees. </li>
            <li>A Notice to Quit that lays out each month and fee being charged.</li>
          </ul><br>
        </p>

        <b>What is good faith participation in Eviction Diversion?</b><br><br>
        <p class="faq-line-indent">
          Where landlords claim non-payment of rent, the goal is to facilitate financial assistance and/or repayment agreements
          and to avoid eviction whenever possible through mutually beneficial agreements. Where there are other issues, the goal
          is to facilitate an agreement that benefits both parties and prevents a disruptive eviction.

          Good faith participation means landlords must:<br>
          <ul>
            <li>
              Provide evidence that the 2022 Notice of Diversion Rights was sent to tenant, updated ledger account of any
              balances owed, and any additional issues to address through mediation as required.
            </li>
            <li>Provide current and accurate contact information for tenant as part of the Eviction Diversion Program application.</li>
            <li>
              Respond timely to requests for communication from a tenant, a housing counselor, or City-funded agency representative
              to resolve application issues or reach an agreement.
            </li>
            <li>
              Attend mediation sessions as scheduled. A landlord may have an agent attend, but the landlord must be available by
              phone to participate if needed. The agent must have all necessary information and authority to resolve disputes and
              enter into agreements.
            </li>
            <li>
              During mediation, the landlord must make reasonable efforts to address all issues that could lead to eviction.
            </li>
            <!-- <li>
              If eligible for Targeted Financial Assistance, a landlord must apply for TFA within 5 days of EDP application approval.
              Landlords will be notified of eligibility and provided with instructions for the TFA application.
            </li> -->
          </ul>
          <br><br>Good faith participation means tenants must:<br>
          <ul>
            <li>
              Tenants must actively participate by attending mediation sessions as scheduled. If not, a tenant risks non-compliance
              and the legal process of eviction.
            </li>
            <li>Tenants must attempt to address all issues raised.</li>
            <li>Tenants must submit all requested materials for Targeted Financial Assistance within 5 days of request.</li>
          </ul>
        </p>

        <br><br>
        <p class="faq-line">
          I am a tenant and I received a notice from the City’s Eviction Diversion Program.  What do I do next?
        </p>
        <p class="faq-line-indent">
          Follow the appropriate resources assigned in the message including possibly a webinar session and direct negotiation
          with your landlord.
        </p>
        <p class="faq-line-indent">
          If you do not receive an email or text message within seven days of receiving the Notice of Tenant rights, you may call
          the Eviction Diversion Program Tenant Hotline at <a href="tel:215-523-9501"> 215-523-9501</a> to find out your next steps.
        </p>
        <p class="faq-line-indent">
          If you have other questions about your rights and responsibilities as a tenant visit <a href="https://phillytenant.org/"><u>www.phillytenant.org</u></a>
          or call the Philly Tenant Hotline at <a href="tel:267-443-2500">267-443-2500</a> and press 2.
        </p>

        <p class="faq-line">
          Can a tenant apply for the Eviction Diversion Program?
        </p>
        <p class="faq-line-indent">
          No. Tenants cannot apply for the Eviction Diversion Program at this time.
          Tenants may seek an alternative through CORA Good Shepherd Mediation by email  <a href="mailto:evictiondiversionprogram@coraservices.org">
            <u>EvictionDiversionProgram@coraservices.org</u>
          </a>.
          CORA Good Shepherd Mediation remains committed to equal access to mediation resources as part of its mission.
        </p>

        <p class="faq-line">
          How long does the Eviction Diversion Program take?
        </p>
        <p class="faq-line-indent">
          The Eviction Diversion Program is a 30-day program. The 30-day clock begins when the landlord’s application for Eviction Diversion, including all required documents, is approved. The program is considered completed when an agreement has been reached or the 30th day has passed.
        </p>
        <br><br><b>What is Targeted Financial Assistance?</b><br><br>
        <p class="faq-line-indent">
          Targeted Financial Assistance (TFA) is a one-time payment from the City to a landlord to cover a tenant’s rent arrears. 
          TFA may be awarded if a tenant and landlord are eligible and participate in the Eviction Diversion Program in good faith. 
          The award is equal to the total amount of arrears at the time of application submission plus, unless there is a graceful exit, two additional months of rent. 
          The two additional months of rent may only be applied to rent the tenant owes to the landlord for rental of the same unit for months succeeding the last month covered by TFA for arrears. 
          These funds may not be used for any other purpose including, but not limited to, security deposits. 
          A utility is only eligible for TFA if the lease obligates the tenant to make the utility payment to the landlord and the landlord makes the utility payment to the utility provider. 
          If you are eligible for TFA, you will be asked to complete a separate application after you apply for EDP. The City has the discretion to not issue TFA payments for applications that contain excessive legal fees.
        </p>

        <br><br><b>Who can apply for Targeted Financial Assistance?</b><br><br>
        <p class="faq-line-indent">
          Landlords may be selected to apply for TFA, which is paid directly to a landlord in order to wipe out their tenant’s rent arrears. The application asks landlords to provide tenants’ contact information, and a list of all tenant household members. 
          Landlords remain eligible as long as they have not yet started an eviction filing for this tenant and unit since the launch of TFA on January 26th, 2023.
        </p>

        <br><br><b>How do I apply for Targeted Financial Assistance?</b><br><br>
        <p class="faq-line-indent">
          First, apply to the Eviction Diversion Program, available here: <a href="https://eviction-diversion.phila.gov/#/">https://eviction-diversion.phila.gov/#/</a>.
          If you are eligible for Targeted Financial Assistance, a service representative will reach out to you by email to request that you complete a separate application for TFA. For guidance on completing the TFA application, view this <a href="https://www.canva.com/design/DAFZiHhBLW8/CIAZfpYtlTMni2i3wPHhFA/watch?utm_content=DAFZiHhBLW8&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink"><u>video</u></a>.
          If you already have a rental assist portal account, view this abbreviated <a href="https://www.canva.com/design/DAFZ0e6CEVw/SBOeiyv2OAXegjqziqmfLg/watch?utm_content=DAFZ0e6CEVw&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink"><u>video</u></a>.
        </p>

        <br><br><b>How is eligibility for Targeted Financial Assistance determined?</b><br><br>
        <p class="faq-line-indent">
          Landlords must first apply for the Eviction Diversion Program. Targeted Financial Assistance can be awarded if:
          <ul>
            <li>An Eviction Diversion Program application is approved with an approval date of 1/26/2023 or later. </li>
            <li>Tenant rental arrears are $3,500 or less, as shown on a ledger or rent roll. </li>
            <li>
              Tenant income is less than <a href="https://www.phila.gov/departments/division-of-housing-and-community-development/about/income-guidelines/">
                <u>80% of the Area Median Income (AMI)</u>
              </a>.
            </li>
            <li>
              A written agreement has been negotiated and signed by the tenant and landlord for any issues other than rent.
              This agreement can be reached through mediation or direct negotiation.
            </li>
            <li>
              The landlord has not yet started an eviction filing for the same tenant and unit since January 26th, 2023.
            </li>
            <li>
              Any court filing dated on or after January 26, 2023, connected to the landlord and tenant pair and property associated with an EDP application, automatically disqualifies a Landlord from receiving TFA funds. If a landlord withdraws a filing, they remain ineligible for TFA.
            </li>
          </ul>
        </p>
        <br><br><b>What documents do I need to apply for Targeted Financial Assistance?</b><br>
        <p class="faq-line-indent">
          <ul>
            <li>
              <b>Bank verification document:</b> Must include the account holder’s name, account number, and routing number. Examples:
              voided check, bank statement, or account detail screenshot.
            </li>
            <li>
              <b>IRS Form W9 2023:</b> Must match the bank account holder’s name. If you do not have a copy of your W9, download a fillable
              copy from the IRS at <a href="https://www.irs.gov/forms-pubs/about-form-w-9"><u>www.irs.gov/forms-pubs/about-form-w-9</u></a> and complete the form.
            </li>
            <li>
              <b>Proof of property ownership or authorization to act on behalf of the property owner:</b> If public records verifying
              property ownership are not available, you must provide a deed or proof of homeowner’s insurance coverage. If an agent or
              representative is working on behalf of the owner, a property management agreement, articles of incorporation, or attorney
              authorization will be required.
            </li>
            <li>
              <a href="https://dpd-eviction-diversion-documents.s3.amazonaws.com/Landlord+Affidavit_11.3.2023.pdf"><u>Landlord affidavit</u></a>: You must download and sign the landlord affidavit for the final step of the application.
            </li>
          </ul><br>
        </p>
        <b>Additional documents that may be requested after you apply:</b>
        <p class="faq-line-indent">
          <ul>
            <li><b>Lease:</b> a current, signed copy of the lease.</li>
          </ul>
        </p>
        <br><br>Files should be uploaded separately. For each document, include all pages in a single file<br><br>

        <br><br><b>I am a landlord applying for Targeted Financial Assistance. Do I need my tenant’s information?</b><br><br>
        <p class="faq-line-indent">
          Yes. Landlords will be asked to enter the following tenant information on the application form:<br>
          <ul>
            <li>Address, including unit number if applicable.</li>
            <li>Names and contact information of all household members.</li>
          </ul>
        </p>

        <br><b>My landlord has applied to Targeted Financial Assistance. As a tenant, what documents do I need to submit?</b><br><br>
        <p class="faq-line-indent">
          If eligible for TFA, tenants will be contacted and asked to submit:<br>
          <ul>
            <li><a href="https://dpd-eviction-diversion-documents.s3.amazonaws.com/Tenant+Affidavit_11.3.2023.pdf"><u>Tenant Affidavit</u></a> (with wet ink or electronic signature).</li>
            <li>Proof of identity and address may be requested (image of ID, utility bill, public benefits statement or paystub).</li>
          </ul>
        </p>

        <br><br><b>How long does Targeted Financial Assistance take?</b><br><br>
        <p class="faq-line-indent">
          If all required information and documents are provided within five business days of request, and any required mediation is
          completed in good faith, Targeted Financial Assistance applications will be processed in 30 days. The 30-day clock begins
          when your Eviction Diversion Program application is approved as complete, including all required documents. Shortly afterward,
          you will be asked to apply for Targeted Financial Assistance if you are eligible.
        </p>

        <br><br><b>How much can a landlord receive in TFA?</b><br><br>
        <p class="faq-line-indent">
          Pending eligibility, Targeted Financial Assistance can cover rent arrears plus two months’ rent.
        </p>

        <br><br><b>How does Targeted Financial Assistance affect the eviction process?</b><br><br>
        <p class="faq-line-indent">
          By accepting these funds, landlords may not begin eviction proceedings until sixty 60 days from the last covered month of financial assistance.
          60 days begins on the first day of the following month.
        </p>

        <br><br><b>Can I apply for Targeted Financial Assistance more than once?</b><br><br>
        <p class="faq-line-indent">
          After receiving TFA funds for a tenant, the landlord is not eligible to receive TFA funds again for the same tenant and unit. It is a one-time payment.
        </p>

        <br><br><b>How will the landlord and tenant know where they are in the Eviction Diversion process?</b><br><br>
        <p class="faq-line-indent">
          Both the landlord and the tenant will receive notifications from the Eviction Diversion Program by text message and email.
          Notifications include whether the landlord's application for diversion was approved and the appropriate pathway for next
          steps. For those assigned to a housing counselor and mediation, you will receive updates on dates of mediation and call-in
          information and the outcome of the diversion process. Landlord and tenants may also check the status of your Eviction Diversion application by clicking
          on <a href="https://eviction-diversion.phila.gov/#/Status"><u>https://eviction-diversion.phila.gov/#/Status</u></a> and enter
          your applicant ID found in any correspondence from the program. Applicant IDs start with EVP. You may also reach out to
          <a href="EvictionDiversionProgram@coraservices.org"><u>EvictionDiversionProgram@coraservices.org</u></a>.
        </p>



        <p class="faq-line">
          How successful is the Eviction Diversion Program?
        </p>
        <p class="faq-line-indent">
          In mediations where the landlord and tenant participated, over 90% resulted in an agreement or an agreement to continue to negotiate.
        </p>

        <p class="faq-line-indent">
          Mediation is only successful if both parties participate in good faith.  It is important to enter accurate contact information for the tenant in the application. This includes the tenant's current cell phone number and email address. Please confirm the tenant’s phone number and email address before applying.
          Landlords may also encourage tenants to work with a housing counselor and confirm the time and date of a scheduled mediation.
        </p>

        <p class="faq-line">
          How can I check the status of my application?
        </p>
        <p class="faq-line-indent">
          You can click on<router-link to="/Status">
            <u> https://eviction-diversion.phila.gov/#/Status</u>
          </router-link> and enter your applicant ID found in any correspondence from the program. Applicant IDs start with EVP-.
        </p>

        <p class="faq-line">
          What is mediation in the Eviction Diversion Program?
        </p>
        <p class="faq-line-indent">
          Mediation is a free opportunity for tenants and landlords. Participants work together with the assistance of a neutral third party, called a mediator, to resolve residential rental disputes.
        </p>
        <p class="faq-line-indent">
          Tenants and landlords join mediation by phone in a one hour (or less) mediated session. A trained volunteer mediator facilitates discussion including rent, utility payments, repairs, lease terms and even graceful exits. The goal of mediation is to reach mutually beneficial agreements without the costs of a court process. All tenants must be residents of Philadelphia.
        </p>

        <p class="faq-line">
          Is mediation part of eviction court?
        </p>
        <p class="faq-line-indent">
          No. Mediation is an opportunity for dialogue between tenants and landlords without going to court. The goal is to identify available resources such as rental assistance and to work out mutually beneficial agreements.
        </p>
        <p class="faq-line-indent">
          As of January 1, 2022, Philadelphia Code 9-811 requires landlords to participate in the Eviction Diversion Program in reasonable good faith prior to filing an eviction case against a tenant in court. For more information, visit the <router-link to="/About">
            <u>About Page</u>.
          </router-link>
        </p>
        <p class="faq-line-indent">
          If you have an eviction court hearing in Landlord Tenant Court, you MUST attend your court hearing.  Mediation is NOT a
          hearing date.
        </p>

        <p class="faq-line">
          <br> How do I participate in a mediation session?
        </p>
        <p class="faq-line-indent">
          Mediations are held weekdays 9am-5pm over a telephone conference call. You will receive an automated notice with the time and date of your mediation.
        </p>
        <p class="faq-line-indent">
          The conference call line is <a href="tel:215-701-2767">215-701-2767</a>. The five-digit call in code for the particular conference room is sent by email and text 48 hours before the mediation date.
        </p>
        <p class="faq-line-indent">
          Often more discussion is needed and a second mediation session will be scheduled.  For second and subsequent sessions, a NEW five-digit call in code will be assigned. DO NOT reuse a code previously assigned for a completed mediation.
        </p>
        <p class="faq-line-indent">
          If you do not receive a code 24 hours before a continued mediation date, please email <a href="mailto:EvictionDiversionProgram@coraservices.org"><u>EvictionDiversionProgram@coraservices.org</u></a> or reach out to the assigned housing counselor.

          <br><br>On the call, a trained mediator provided by Good Shepherd Mediation Program facilitates the conversation between landlord and tenant.
        </p>

        <p class="faq-line">
          <br> What happens at the Eviction Diversion Program mediation date?
        </p>
        <p class="faq-line-indent">
          On the call, a trained volunteer mediator provided by CORA Good Shepherd Mediation facilitates the conversation between landlord and tenant. The landlord says what the landlord is seeking. The tenant and the tenant's housing counselor share the tenant's issues, goals and available resources.
          The purpose is to reach a sustainable agreement that works for both sides.
        </p>
        <p class="faq-line-indent">
          Agents or attorneys appearing on behalf of Landlords must have settlement authority.
          If the Landlord does not attend the mediation, the Landlord must be accessible during mediation for consultation.
        </p>
        <p class="faq-line-indent">
          If the landlord and tenant reach an agreement, the agreement is written down by the mediator, verbally signed by parties and provided to both parties by the mediation manager.  Both parties review the agreement and follow the terms.
          Agreements often include regular payments from the tenant and may also include repairs, utility bills, access to the property, and even timelines for a graceful exit or leaving the property.
        </p>
        <p class="faq-line-indent">
          If the landlord and tenant do not reach an agreement and parties have participated in good faith, the parties will receive an email documenting that no agreement was reached.
        </p>
        <p class="faq-line-indent">
          Parties may ask to continue negotiations and choose a new mediation date, especially if additional information and rental assistance updates are needed to reach agreement.
          The tenant should continue to work with the assigned housing counselor if negotiations continue outside or between mediation dates.
        </p>

        <p class="faq-line">
          <br> What if I am not included appropriately in mediation?
        </p>
        <p class="faq-line-indent">
          Mediation is your opportunity to share your concerns. Mediators work to uphold the values of safety, access, and inclusion.
        </p>
        <p class="faq-line-indent">
          Both parties should feel free to participate fully in the process. Mediators facilitate balanced dialogue with the goal of reaching mutually satisfying outcomes and agreements.
          Housing Counselors also attend to support the tenant and to share City resources that support both parties.
        </p>
        <p class="faq-line-indent">
          If you have safety or equity concerns while participating in any stage of the Eviction Diversion Program,
          please email <a href="mailto:EvictionDiversionProgram@coraservices.org"><u>Evictiondiversionprogram@coraservices.org</u></a> for confidential support from a member of the CORA Good Shepherd Mediation staff.
        </p>
        <p class="faq-line-indent">
          For support with domestic violence, please contact the Philadelphia Domestic Violence Hotline - <a href="tel:1-866-723-3014">1-866-723-3014</a>.
        </p>
        <p class="faq-line-indent">
          For interpretation services, please request at the time of application, through the assigned Housing Counselor or by email prior to mediation <a href="mailto:EvictinDiversionProgram@coraservices.org"><u>EvictinDiversionProgram@coraservices.org</u></a>.
        </p>

        <p class="faq-line">
          <br> I have a scheduling issue or I have resolved all issues prior to mediation. What do I do?
        </p>
        <p class="faq-line-indent">
          Contact <a href="mailto:EvictionDiversionProgram@coraservices.org"><u>EvictionDiversionProgram@coraservices.org</u></a> right away. Include the reason you are requesting to reschedule and any details about contact with the landlord or tenant.
          If mediation is no longer necessary, please cite the reason for resolution such as a successful rental assistance plan or tenant brought the rent current.
        </p>
        <p class="faq-line-indent">
          Tenants may reach out to the housing counselor or Hotline staff at 215-523-9501 for additional help.
        </p>
        <p class="faq-line-indent">
          Missed mediation dates may result in documentation that a party did not participate in the eviction diversion program in good faith and could affect eligibility for Targeted Financial Assistance, if applicable.
        </p>

        <p class="faq-line">
          <br>    Who do I contact if I have other questions?
        </p>
        <p class="faq-line-indent">
          For landlord related questions about the Eviction Diversion Program application and mediation process, contact CORA Good Shepherd Mediation at <a href="mailto:EvictionDiversionProgram@coraservices.org"><u>EvictionDiversionProgram@coraservices.org</u></a>.
          <br><br>For tenant related questions about the Eviction Diversion Program application and mediation process, call PLA’s Eviction Diversion Program Tenant Hotline at 215-523-9501.
          <br><br>For general questions about tenants’ rights, free legal advice and possible representation, tenants may also call the Philly Tenant Hotline at 267-443-2500.
        </p>

        <p class="faq-line">
          <br>     Where can I find out more about resources for tenants and tenant help?
        </p>
        <p class="faq-line-indent">
          Visit <a
            target="#"
            href="https://phillytenant.org"
          ><u>www.phillytenant.org</u></a> or call the Eviction Diversion Program Tenant Hotline at 215-523-9501.
        </p>
        <br><br><br><br><br><br>

        <!--start here, add router link to About page -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FAQ",
};
</script>

<style>
.faq-line {
margin:0in;
font-weight: bold;
}
.faq-line-indent {
margin:0in;
margin-left:.5in;
margin-bottom: 5px;
}
.faq-list{
  margin-left:100px !important
}
.last{
  margin-bottom: 50px;
}
</style>
