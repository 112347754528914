<template>
  <div class="container">
    <div class="columns is-centered">
      <div class="column">
        <ValidationObserver
          v-slot="{ errors }"
          tag="div"
        >
          <input-form
            ref="form"
            :errors="errors"
            name="LandlordApplicationFrm"
            @submit.prevent="handleSubmit"
          >
            <h1>Check Your Application Status</h1>
            <callout
              type="info"
            >
              Please enter your Applicant ID (starting with EVP-).<br>Your Applicant ID is included in any correspondence you receive from the PHL Eviction Diversion system (emails &amp; text messages).
            </callout>
            <textbox
              v-model.trim="ApplicantId"
              name="txt-applicant-id"
              placeholder="Applicant ID:"
            />
            <div class="column is-2 is-offset-10">
              <button
                type="button"
                :disabled="[SubmitStatus.Options.PENDING].includes(SubmitStatus)"
                class="button is-primary"
                @click="handleSubmit"
              >
                Check Status
              </button>
            </div>
            <div
              v-if="SubmitStatus.Value === SubmitStatus.Options.OK"
              class="cell"
            >
              <label for="status-check-result-list">EDP Application Status</label>
              <ul
                v-if="StatusCheckResult"
                id="status-check-result-list"
                name="status-check-result-list"
              >
                <li
                  v-for="(result, idx) in StatusCheckResult"
                  :key="idx"
                >
                  <div v-html="result" />
                </li>
              </ul>
            </div>
            <div v-else-if="SubmitStatus.Value === SubmitStatus.Options.PENDING">
              Loading...
            </div>
            <div v-else-if="SubmitStatus.Value === SubmitStatus.Options.NOT_FOUND">
              <callout
                message="An application with that ID cannot be found, please ensure you have the correct Applicant ID and try again."
                type="danger"
              />
            </div>
            <br>
          </input-form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import options from "@/utilities/options";
import buildStatusCheckDataObject from "@/utilities/data-objects/status-check-data-object";
import axios from "axios";
import {
  getPropertyValueOrDefault,
  toAnchorTag,
} from "@/utilities/functions";

export default {
  mixins: [options],
  props: {
    applicantId: { type: String, default: null },
  },
  data() {
    return {
      StatusCheckResult: null,
      ApplicantId: null,
    };
  },
  created() {
    var vData = this;
    if (vData.applicantId) {
      vData.ApplicantId = vData.applicantId;
    }
  },
  methods: {
    async handleSubmit() {
      var vData = this;
      vData.SubmitStatus.Value = vData.SubmitStatus.Options.PENDING;
      const dataObject = buildStatusCheckDataObject(
        vData.ApplicantId,
        "EDP_STATUS_CHECK_DATA_REQUEST",
      );
      const requestObj = {
        table: "bqseac2yd",
        data: dataObject,
      };
      axios
        .post(
          "https://djql8jp3sb.execute-api.us-east-1.amazonaws.com/V1/Eviction-Diversion-Secure-Request-Proxy",
          requestObj,
        )
        .then(qbResult => {
          vData.SubmitStatus.Value = vData.SubmitStatus.Options.OK;
          const singleReturnValue = getPropertyValueOrDefault(
            ["data", "record", "f", "_text"],
            qbResult,
          );
          const multipleReturnValue = getPropertyValueOrDefault(
            ["data", "record", "f"],
            qbResult,
          );
          if (singleReturnValue) {
            let cleanReturnValue = singleReturnValue.replace(
              /(\\r)*\\n/g,
              "<br>",
            );
            const isAnchor = /<a[^>]*>([^<]+)<\/a>/g.test(singleReturnValue);
            if (!isAnchor) {
              cleanReturnValue = toAnchorTag(cleanReturnValue);
            }
            vData.StatusCheckResult = cleanReturnValue.split(";");
          } else if (multipleReturnValue) {
            // do something with multiple values...
            vData.StatusCheckResult = multipleReturnValue.map(i => i._text);
          } else {
            vData.SubmitStatus.Value = vData.SubmitStatus.Options.NOT_FOUND;
          }
        });
    },
  },
};
</script>

<style></style>
