import DataObjectField from '@/utilities/classes/data-object-field';

const buildSurveySubmissionDataObject = (vData) => {

  const fields = [

    //Generic
    new DataObjectField(vData.submissionType, 35),
    new DataObjectField(vData.Address.Street1, 13),
    new DataObjectField(vData.Address.Street2, 14),
    new DataObjectField(vData.Address.City, 15),
    new DataObjectField(vData.Address.State, 16),
    new DataObjectField(vData.Address.Zip, 17),
    new DataObjectField(vData.Address.Country, 18),
    new DataObjectField(vData.tenantFirstName, 26),
    new DataObjectField(vData.tenantLastName, 27),
    new DataObjectField(vData.landlordFirstName, 47),
    new DataObjectField(vData.landlordLastName, 48),
    new DataObjectField(vData.negotiationMayStopEviction, 29),
    new DataObjectField(vData.agreementReached, 10),
    new DataObjectField(vData.agreementFairnessScale, 21),
    new DataObjectField(vData.agreementIsInWriting, 22),
    new DataObjectField(vData.agreementIncludesList, 23),
    new DataObjectField(vData.agreementIncludesOther, 31),
    new DataObjectField(vData.noAgreementReasonsOther, 32),
    new DataObjectField(vData.issuesOtherThanRentList, 36),
    new DataObjectField(vData.agreementReachedFeedback, 19),
    new DataObjectField(vData.shareSuccess, 24),
    new DataObjectField(vData.applicantId, 64),

    //Landlord
    new DataObjectField(vData.landlordNoAgreementReasonsList, 20),

    //Tenant
    new DataObjectField(vData.tenantCanMaintainPayments, 30),
    new DataObjectField(vData.tenantNoAgreementReasonsList, 34),

  ];

  const dataObject = {
    qdbapi: {
      udata : "EVICTION_DIVERSION_APPLICATION_SURVEY_SUBMISSION",
      apptoken: "dzwgfmadxqgi7jdhhcmti28pk27",
      field: fields,
    },
  };
  return dataObject;
};

export default buildSurveySubmissionDataObject;
