const buildStatusCheckDataObject = (applicantId, uData) => {
  const dataObject = {
    qdbapi: {
      udata: uData,
      apptoken: "dedvi9yd3f28pebigie6ccqxs2qy",
      ApplicantId: applicantId,
      fmt: "structured",
    },
  };
  if (applicantId) {
    dataObject.qdbapi.query = `{'32'.EX.'${applicantId}'}`;
  }
  return dataObject;
};

export default buildStatusCheckDataObject;
